import React, { useState, useMemo } from 'react';
import { Form } from 'react-bootstrap';
import { supabase } from '../SupabaseClient';
import Select from 'react-select';
import countryCodes from '../data/countries.json';

function UserSignUp() {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [userProfile, setUserProfile] = useState({
        firstName: '',
        lastName: '',
        username: '',
        city: '',
        country: '',
        instagram: '',
        tiktok: '',
        x: '',
        email: '',
        password: '',
        confirmPassword: '',
        profilePic: null,
        country_code: '',
        dob: '',
        role: 'basic'
    });
    const [submitted, setSubmitted] = useState(false);

    const countries = useMemo(() => {
        return Object.entries(countryCodes).map(([label, value]) => ({
            label,
            value
        }));
    }, []);

    const handleProfileChange = (e) => {
        const { name, value } = e.target;
        setUserProfile((prevProfile) => ({
            ...prevProfile,
            [name]: value
        }));
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setUserProfile(prevProfile => ({
            ...prevProfile,
            profilePic: file
        }));
    };

    const isFormValid = () => {
        const { username, password, confirmPassword } = userProfile;
        const usernameValid = /^[a-z0-9]{1,15}$/.test(username);
        const passwordsMatch = password === confirmPassword;
        return usernameValid && passwordsMatch;
    };

    const uploadProfilePic = async (username) => {
        const profilePicPath = `${username}/profilePicture/${userProfile.profilePic.name}`;
        const { error: profilePicError } = await supabase.storage
            .from('userMedia')
            .upload(profilePicPath, userProfile.profilePic);

        if (profilePicError) throw profilePicError;

        const { data: profilePicData } = supabase.storage
            .from('userMedia')
            .getPublicUrl(profilePicPath);
            
        return profilePicData.publicUrl;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        
        try {
            // 1. Check username availability
            const { data: existingUsername } = await supabase
                .from('users')
                .select('username')
                .eq('username', userProfile.username)
                .single();

            if (existingUsername) {
                throw new Error('This username is already taken');
            }

            // 2. Check if email exists in users table
            const { data: existingUser } = await supabase
                .from('users')
                .select('uuid, email')
                .eq('email', userProfile.email)
                .single();

            let userId;

            if (existingUser) {
                // 3. Check if uuid exists in Auth Users table
                const { data: authUser } = await supabase.auth.admin.getUserById(existingUser.uuid);
                if (authUser.user) {  // Changed from if (authUser)
                    throw new Error('The email you entered is already in use. If you have an account, try signing in');
                }
                // 4. Create new auth user since email exists but no auth user
                const { data: newAuth, error: authError } = await supabase.auth.signUp({
                    email: userProfile.email,
                    password: userProfile.password,
                });
                if (authError) throw new Error(`Authentication error: ${authError.message}`);
                userId = newAuth.user.id;
                // 4a. Update each conversation that contains the old UUID
                const { data: conversations, error: fetchError } = await supabase
                    .from('conversations')
                    .select('conversation_id, users')
                    .contains('users', [existingUser.uuid]);
                if (fetchError) throw new Error(`Error fetching conversations: ${fetchError.message}`);
                for (const conversation of conversations) {
                    const updatedUsers = conversation.users.map(id => 
                        id === existingUser.uuid ? userId : id
                    );
                    const { error: updateError } = await supabase
                        .from('conversations')
                        .update({ users: updatedUsers })
                        .eq('conversation_id', conversation.conversation_id);
                    if (updateError) throw new Error(`Error updating conversation: ${updateError.message}`);
                }
                // 4b. Update messages table
                const { error: msgSenderError } = await supabase
                    .from('messages')
                    .update({ sender_id: userId })
                    .eq('sender_id', existingUser.uuid);

                const { error: msgReceiverError } = await supabase
                    .from('messages')
                    .update({ receiver_id: userId })
                    .eq('receiver_id', existingUser.uuid);

                if (msgSenderError || msgReceiverError) {
                    throw new Error('Error updating messages');
                }

                // 4c. Update users table
                const { error: userError } = await supabase
                    .from('users')
                    .update({ uuid: userId })
                    .eq('uuid', existingUser.uuid);

                if (userError) throw new Error(`User update error: ${userError.message}`);

            } else {
                // Normal signup flow for new email
                const { data: authData, error: authError } = await supabase.auth.signUp({
                    email: userProfile.email,
                    password: userProfile.password,
                });

                if (authError) throw new Error(`Authentication error: ${authError.message}`);
                userId = authData.user.id;
            }

            // Upload profile picture and create/update user profile
            const profilePicURL = await uploadProfilePic(userProfile.username);

            const { error: profileError } = await supabase
                .from('users')
                .upsert({
                    uuid: userId,
                    firstName: userProfile.firstName,
                    lastName: userProfile.lastName,
                    username: userProfile.username,
                    city: userProfile.city,
                    country: userProfile.country,
                    instagram: userProfile.instagram,
                    tiktok: userProfile.tiktok,
                    x: userProfile.x,
                    role: 'basic',
                    profile_pic: profilePicURL,
                    dob: userProfile.dob,
                    email: userProfile.email
                });

            if (profileError) throw new Error(`Profile creation error: ${profileError.message}`);

            setIsSubmitting(false);
            setSubmitted(true);
        } catch (error) {
            console.error('Detailed error:', error);
            setIsSubmitting(false);
            alert(error.message || 'An error occurred during signup');
        }
    };

    return (
        <div className="signup-container">
            {submitted ? (
                <div className="contact-form">
                    <div className='request-title'>
                        Your profile has been successfully created!
                    </div>
                    <div className='contact-received-text'>
                        You should receive an email to activate your account shortly.
                    </div>
                    <div className='contact-received-text'>
                        Once you activate your account, you can sign in to view all of your ongoing conversations.
                    </div>
                </div>
            ) : (
                <Form onSubmit={handleSubmit} className="signup-form">
                    <div className="request-header">Become a Fan</div>
                    <div className='contact-received-text' style={{ marginTop: '0px', marginBottom: '0px' }}>
                        Creating an account will allow you to view all of your ongoing conversations in one place.
                    </div>
                    <div className='contact-received-text' style={{ marginTop: '0px' }}>
                        If you want to join the platform as an athlete, visit the athlete signup page <a className='contact-redirect-link' href='/signup'><b>here</b></a>.
                    </div>
                    {/* Basic Information */}
                    <Form.Group>
                        <Form.Label>First Name<span className="signup-required">*</span></Form.Label>
                        <Form.Control
                            type="text"
                            name="firstName"
                            value={userProfile.firstName}
                            onChange={handleProfileChange}
                            required
                        />
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>Last Name<span className="signup-required">*</span></Form.Label>
                        <Form.Control
                            type="text"
                            name="lastName"
                            value={userProfile.lastName}
                            onChange={handleProfileChange}
                            required
                        />
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>Date of Birth<span className="signup-required">*</span></Form.Label>
                        <Form.Control
                            type="date"
                            name="dob"
                            value={userProfile.dob}
                            onChange={handleProfileChange}
                            required
                        />
                    </Form.Group>

                    {/* Location Information */}
                    <Form.Group>
                        <Form.Label>City<span className="signup-required">*</span></Form.Label>
                        <Form.Control
                            type="text"
                            name="city"
                            value={userProfile.city}
                            onChange={handleProfileChange}
                            required
                        />
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>Country<span className="signup-required">*</span></Form.Label>
                        <Select
                            className="signup-country-dropdown"
                            options={countries}
                            value={countries.find(option => option.value === userProfile.country_code)}
                            onChange={(selectedOption) => {
                                setUserProfile({
                                    ...userProfile,
                                    country: selectedOption.label,
                                    country_code: selectedOption.value,
                                });
                            }}
                            required
                        />
                    </Form.Group>

                    {/* Account Information */}
                    <Form.Group>
                        <Form.Label>Email<span className="signup-required">*</span></Form.Label>
                        <Form.Control
                            type="email"
                            name="email"
                            value={userProfile.email}
                            onChange={handleProfileChange}
                            required
                        />
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>Username<span className="signup-required">*</span></Form.Label>
                        <Form.Text className="text-muted">
                            Must be lowercase letters and numbers only, max 15 characters.
                        </Form.Text>
                        <Form.Control
                            type="text"
                            name="username"
                            value={userProfile.username}
                            onChange={handleProfileChange}
                            required
                            maxLength={15}
                            pattern="[a-z0-9]*"
                        />
                    </Form.Group>

                    {/* Social Media */}
                    <Form.Group>
                        <Form.Label>Instagram</Form.Label>
                        <Form.Control
                            type="text"
                            name="instagram"
                            value={userProfile.instagram}
                            onChange={handleProfileChange}
                        />
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>TikTok</Form.Label>
                        <Form.Control
                            type="text"
                            name="tiktok"
                            value={userProfile.tiktok}
                            onChange={handleProfileChange}
                        />
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>X/Twitter</Form.Label>
                        <Form.Control
                            type="text"
                            name="x"
                            value={userProfile.x}
                            onChange={handleProfileChange}
                        />
                    </Form.Group>

                    {/* Profile Picture */}
                    <Form.Group>
                        <Form.Label>Profile Picture<span className="signup-required">*</span></Form.Label>
                        <Form.Control
                            type="file"
                            accept="image/*"
                            required
                            onChange={handleFileChange}
                        />
                        {userProfile.profilePic && (
                            <img
                                src={URL.createObjectURL(userProfile.profilePic)}
                                alt="Profile Thumbnail"
                                className='signup-picture'
                                style={{ width: '100px', height: '100px', objectFit: 'cover', marginTop: '10px' }}
                            />
                        )}
                    </Form.Group>

                    {/* Password */}
                    <Form.Group>
                        <Form.Label>Password<span className="signup-required">*</span></Form.Label>
                        <Form.Control
                            type="password"
                            name="password"
                            value={userProfile.password}
                            onChange={handleProfileChange}
                            required
                        />
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>Confirm Password<span className="signup-required">*</span></Form.Label>
                        <Form.Control
                            type="password"
                            name="confirmPassword"
                            value={userProfile.confirmPassword}
                            onChange={handleProfileChange}
                            required
                        />
                    </Form.Group>

                    <button
                        className="request-button"
                        type="submit"
                        disabled={!isFormValid() || isSubmitting}
                    >
                        {isSubmitting ? (
                            <div style={{ display: 'flex', justifyContent: "center", alignItems: 'center' }}>
                                Submitting
                                <div className="loader-button" style={{ marginLeft: '10px' }}></div>
                            </div>
                        ) : (
                            "Create Account"
                        )}
                    </button>
                </Form>
            )}
        </div>
    );
}

export default UserSignUp; 