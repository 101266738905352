import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { supabase } from '../SupabaseClient';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { IoIosStar } from "react-icons/io";
import ConfirmationAlert from '../components/ConfirmationAlert';
import SecureVideoPlayer from '../components/SecureVideoPlayer';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import axios from 'axios';

import { BiSolidMessageRoundedAdd } from "react-icons/bi";
import { FaUser } from "react-icons/fa";

// Initialize Stripe
const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`);

const ViewPage = () => {
    const { message_id } = useParams();
    const [athleteID, setAthleteID] = useState(null);
    const [questionMessage, setQuestionMessage] = useState(null);
    const [responseMessage, setResponseMessage] = useState(null);
    const [athleteData, setAthleteData] = useState(null);
    const [rating, setRating] = useState(5.0);
    const [feedback, setFeedback] = useState('');
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [wantsToRate, setWantsToRate] = useState(true); // New state variable
    const [responseFeedback, setResponseFeedback] = useState(''); // New state variable
    const [selectedDonation, setSelectedDonation] = useState(0);
    const [isCustomDonationActive, setIsCustomDonationActive] = useState(false);
    const [clientSecret, setClientSecret] = useState('');
    const [isPaymentValid, setIsPaymentValid] = useState(false);
    const [paymentError, setPaymentError] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showConfirmationAlert, setShowConfirmationAlert] = useState(false);
    const [showPaymentForm, setShowPaymentForm] = useState(false);

    useEffect(() => {
        setFormSubmitted(false);
        const fetchMessages = async () => {
            // First fetch the question message
            const { data: questionData, error: questionError } = await supabase
                .from('messages')
                .select('*')
                .eq('message_id', message_id)
                .single();

            if (questionError) {
                console.error('Error fetching question:', questionError);
                return;
            }
            setAthleteID(questionData.receiver_id);

            setQuestionMessage(questionData);

            // Then fetch the response message using the conversation_id
            const { data: responseData, error: responseError } = await supabase
                .from('messages')
                .select('*')
                .eq('conversation_id', questionData.conversation_id)
                .eq('q_or_a', 'answer')
                .gt('created_at', questionData.created_at)
                .order('created_at', { ascending: true })
                .limit(1)
                .single();

            if (responseError) {
                console.error('Error fetching response:', responseError);
                return;
            }
            setResponseMessage(responseData);

            // Check if rating exists to set formSubmitted
            if (responseData.rating != null) {
                setFormSubmitted(true);
            }
        };
        fetchMessages();
    }, [message_id]);

    useEffect(() => {
        if (athleteID) {
            const fetchAthleteData = async () => {
                const { data, error } = await supabase
                    .from('users')
                    .select('*')
                    .eq('uuid', athleteID)
                    .single();
                if (error) {
                    console.error('Error fetching athlete data:', error);
                } else {
                    setAthleteData(data);
                }
            };
            fetchAthleteData();
        }
    }, [athleteID]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // Update the responses table as before
            if (wantsToRate) {
                const newRating = athleteData.rating_total + rating;
                const newCount = athleteData.rating_count + 1;
                const { error: athleteUpdateError } = await supabase
                    .from('users')
                    .update({
                        rating_total: newRating,
                        rating_count: newCount
                    })
                    .eq('uuid', athleteID);
                if (athleteUpdateError) {
                    console.error('Error updating athlete data:', athleteUpdateError);
                    return;
                }
            }

            // Insert into request-feedback table
            const { error: feedbackError } = await supabase
                .from('requester_feedback')
                .insert({
                    athlete_username: athleteData.username,
                    response_id: responseMessage.message_id,
                    request_id: questionMessage.message_id,
                    rating: rating,
                    infield_feedback: feedback,
                    response_feedback: responseFeedback
                });

            if (feedbackError) {
                console.error('Error inserting feedback:', feedbackError);
                return;
            }

            setShowConfirmation(true);
            setFormSubmitted(true);
        } catch (error) {
            console.error('Error submitting feedback:', error);
        }
    };

    const getVideoUrl = (path) => {
        if (!path) return null;
        const { data: { publicUrl } } = supabase.storage
          .from('conversations')
          .getPublicUrl(path);
        return publicUrl;
      };

    const handleStarClick = (index) => {
        setRating(parseFloat((index + 1).toFixed(1)));
    };

    const toggleRating = () => {
        setWantsToRate(!wantsToRate);
    };

    const handleConfirmDonation = async () => {
        try {
            const { data: requesterData, error: requesterError } = await supabase
            .from('users')
            .select('firstName, lastName, email')
            .eq('uuid', questionMessage.sender_id)
            .single();

            if (requesterError) {
                console.error('Error fetching requester data:', requesterError);
                return;
            }
            const response = await axios.post('https://infield-backend.vercel.app/create-payment-intent', {
                amount: selectedDonation,
                currency: 'usd',
                capture_method: 'automatic', 
                firstName: requesterData.firstName, 
                lastName: requesterData.lastName, 
                email: requesterData.email, 
                athlete_id: athleteID,
                athlete_username: athleteData.username,
                athlete_stripe_id: athleteData.stripe_account_id
            });
            setClientSecret(response.data.clientSecret);
            setShowPaymentForm(true);
        } catch (error) {
            console.error('Error creating payment intent:', error);
        }
    };

    const handleCancelDonation = () => {
        setShowPaymentForm(false);
        setClientSecret('');
        setIsPaymentValid(false);
        setPaymentError('');
    };

    const handleDonation = (amount) => {
        if (!showPaymentForm) {  // Only allow changes if payment form isn't shown
            if (selectedDonation === amount) {
                setSelectedDonation(0);
            } else {
                setSelectedDonation(amount);
            }
            setIsCustomDonationActive(false);
        }
    };

    const toggleCustomDonation = () => {
        if (!showPaymentForm) {  // Only allow changes if payment form isn't shown
            setIsCustomDonationActive(!isCustomDonationActive);
            if (isCustomDonationActive) {
                setSelectedDonation(0);
            }
        }
    };

    const handleCustomDonationChange = (event) => {
        if (!showPaymentForm) {  // Only allow changes if payment form isn't shown
            const customAmount = parseFloat(event.target.value);
            if (customAmount >= 2) {
                setSelectedDonation(customAmount * 100);
            } else {
                setSelectedDonation(0);
            }
        }
    };

    if (!questionMessage || !responseMessage || !athleteData) {
        return (
            <div className="dashboard-page-format">
                <div className="loader-ring"><div></div><div></div><div></div><div></div></div>
            </div>
        );
    } else {
        return (
            <div className='view-page-format'>
                <div className="view-form">
                    <div className="view-header">Your response from {athleteData.firstName} has arrived!</div>
                    <div className='view-response-container'>
                        <div className='view-video-col'>
                            <div className='view-video-title'>Your request</div>
                            {questionMessage.text_or_video === 'text' && 
                                <div className='view-text-message' style={{padding:"5px"}}>
                                    {questionMessage.message}
                                </div>
                            }
                            {questionMessage.text_or_video === 'video' && (
                                <SecureVideoPlayer
                                    src={getVideoUrl(questionMessage.video_url)}
                                    className='admin-dashboard-video'
                                />
                            )}
                        </div>
                        <div className='view-video-col'>
                            <div className='view-video-title'>{athleteData.firstName}'s response</div>
                            <SecureVideoPlayer
                                src={getVideoUrl(responseMessage.video_url)}
                                className='admin-dashboard-video'
                            />
                        </div>
                    </div>
                    <div className='view-response-donation'>
                        <div className='view-response-title'>
                            <b>Keep the conversation going</b>
                        </div>
                        <div className='view-split-container'>
                            <div className='view-split-section account-section'>
                                <div className='view-split-subtitle'>
                                    Create a free account
                                </div>
                                <div className='view-split-description'>
                                    Creating an account lets you view all of your past and present conversations in one place
                                </div>
                                <Button 
                                    className='view-payment-button' 
                                    href='/createaccount'
                                    style={{ backgroundColor: 'var(--colorTertiary) !important' }}
                                >
                                    <FaUser className='home-pitch-button-icon' />
                                    Create an account
                                </Button>
                            </div>
                            <div className='view-split-or'>
                                or
                            </div>
                            <div className='view-split-section request-section'>
                                <div className='view-split-subtitle'>
                                    Make another request
                                </div>
                                <div className='view-split-description'>
                                    You can always make requests without an account, but you won't be able to view all of your requests in the same place
                                </div>
                                <Button className='view-payment-button' href={`https://www.infieldconnect.co/athletes/${athleteData.username}`}>
                                    <BiSolidMessageRoundedAdd className='home-pitch-button-icon' />
                                    New Request
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className="view-feedback-container">
                        <div className='view-response-donation'>
                            <div className='view-response-title' style={{textAlign: "start"}}>
                                <b>Send {athleteData.firstName} something for their time</b>
                            </div>
                            <div className="donation-container">
                                <div className="view-donation-subcontainer">
                                    <button
                                        type="button"
                                        className={`donation-button ${selectedDonation === 500 ? 'selected' : ''}`}
                                        onClick={() => handleDonation(500)}
                                        disabled={showPaymentForm}
                                    >
                                        $5
                                    </button>
                                    <button
                                        type="button"
                                        className={`donation-button ${selectedDonation === 1000 ? 'selected' : ''}`}
                                        onClick={() => handleDonation(1000)}
                                        disabled={showPaymentForm}
                                    >
                                        $10
                                    </button>
                                    <button
                                        type="button"
                                        className={`donation-button ${isCustomDonationActive ? 'selected' : ''}`}
                                        onClick={toggleCustomDonation}
                                        disabled={showPaymentForm}
                                    >
                                        Custom
                                    </button>
                                    {isCustomDonationActive && (
                                        <input
                                            type="number"
                                            min="1"
                                            placeholder="Enter amount"
                                            className="donation-input"
                                            onChange={handleCustomDonationChange}
                                            disabled={showPaymentForm}
                                        />
                                    )}

                                    {selectedDonation > 0 && (
                                        <button
                                            className={`${showPaymentForm ? 'view-cancel-button' : 'view-confirm-button'}`}
                                            onClick={showPaymentForm ? handleCancelDonation : handleConfirmDonation}
                                        >
                                            {showPaymentForm ? 'Cancel' : 'Confirm'}
                                        </button>
                                    )}
                                </div>
                                {showPaymentForm && (
                                    <>
                                        <div className="price-display">
                                            <span>Donation amount</span>
                                            <span>${(selectedDonation / 100).toFixed(2)}</span>
                                        </div>
                                        <Elements stripe={stripePromise} options={{ clientSecret }}>
                                            <PaymentForm
                                                setShowConfirmationAlert={setShowConfirmationAlert}
                                                setSelectedDonation={setSelectedDonation}
                                                isPaymentValid={isPaymentValid}
                                                setIsPaymentValid={setIsPaymentValid}
                                                paymentError={paymentError}
                                                setPaymentError={setPaymentError}
                                                isSubmitting={isSubmitting}
                                                setIsSubmitting={setIsSubmitting}
                                                athleteID={athleteID}
                                                selectedDonation={selectedDonation}
                                                setShowPaymentForm={setShowPaymentForm}
                                            />
                                        </Elements>
                                    </>
                                )}
                            </div>
                        </div>

                        {!formSubmitted && responseMessage.rating == null && (
                            <Form className='view-response-feedback' onSubmit={handleSubmit}>
                                <Form.Group>
                                    <Form.Label className='view-response-title'>If you liked {athleteData.firstName}'s response, rate it!</Form.Label>
                                    {wantsToRate && (
                                        <div className="view-star-rating">
                                            {[...Array(5)].map((star, index) => (
                                                <IoIosStar
                                                    key={index}
                                                    className={index < rating ? 'view-star selected' : 'view-star'}
                                                    onClick={() => handleStarClick(index)}
                                                />
                                            ))}
                                        </div>
                                    )}
                                    <div onClick={toggleRating} className='view-rating-text'>
                                        {wantsToRate ? "I don't want to rate" : "Add a rating"}
                                    </div>
                                </Form.Group>
                                <Form.Group style={{marginTop: "min(2vw,20px)"}}>
                                    <Form.Label className='view-response-title text-left'>What did you think about {athleteData.firstName}'s response?</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder='(Optional)'
                                        className='view-response-text'
                                        rows={2}
                                        value={responseFeedback}
                                        onChange={(e) => setResponseFeedback(e.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group style={{marginTop: "min(2vw,20px)"}}>
                                    <Form.Label className='view-response-title text-left'>
                                        How can Infield change to improve your experience?
                                    </Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        placeholder='(Optional)'
                                        className='view-response-text'
                                        rows={2}
                                        value={feedback}
                                        onChange={(e) => setFeedback(e.target.value)}
                                    />
                                </Form.Group>

                                <button type="submit" className='view-feedback-button'>Submit Feedback</button>
                            </Form>
                        )}
                    </div>
                    {showConfirmationAlert && (
                        <ConfirmationAlert
                            message="Payment successfully sent!"
                            onClose={() => setShowConfirmationAlert(false)}
                        />
                    )}
                    {showConfirmation && (
                        <ConfirmationAlert
                            message="Thank you for your feedback!"
                            onClose={() => setShowConfirmation(false)}
                        />
                    )}
                </div>
            </div>
        );
    }
};

const PaymentForm = ({ 
    setShowConfirmationAlert, 
    setSelectedDonation, 
    isPaymentValid, 
    setIsPaymentValid, 
    paymentError, 
    setPaymentError, 
    isSubmitting, 
    setIsSubmitting,
    athleteID,
    selectedDonation,
    setShowPaymentForm
}) => {
    const stripe = useStripe();
    const elements = useElements();

    const handleSubmitPayment = async () => {
        setIsSubmitting(true);

        if (!stripe || !elements) {
            console.error('Stripe or elements not initialized');
            setIsSubmitting(false);
            return;
        }

        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {},
            redirect: 'if_required',
        });

        if (error) {
            console.error('Payment verification failed:', error);
            setPaymentError(error.message || 'An unknown error occurred during payment verification.');
            setIsSubmitting(false);
            return;
        }

        // Update athlete earnings
        try {
            const { data: athleteData, error: fetchError } = await supabase
                .from('users')
                .select('earningsTotal')
                .eq('uuid', athleteID)
                .single();

            if (fetchError) throw fetchError;
            const newEarningsTotal = (athleteData.earningsTotal || 0) + ((selectedDonation/100) - ((selectedDonation/100) * 0.029 + 0.3));

            const { error: updateError } = await supabase
                .from('users')
                .update({ earningsTotal: newEarningsTotal })
                .eq('uuid', athleteID);

            if (updateError) throw updateError;

            setShowConfirmationAlert(true);
            setSelectedDonation(0);
            setShowPaymentForm(false); // Close the payment form
        } catch (dbError) {
            console.error('Error updating athlete earnings:', dbError);
            setPaymentError('Payment processed but error updating athlete earnings.');
        }

        setIsSubmitting(false);
    };

    return (
        <>
            <PaymentElement
                onChange={(event) => {
                    setIsPaymentValid(event.complete);
                    if (event.error) {
                        setPaymentError(event.error.message);
                    } else {
                        setPaymentError('');
                    }
                }}
            />
            {paymentError && <div className="error-message">{paymentError}</div>}
            <button
                className="view-feedback-button"
                onClick={handleSubmitPayment}
                disabled={!isPaymentValid || isSubmitting}
            >
                {isSubmitting ? 'Processing...' : 'Submit Payment'}
            </button>
        </>
    );
};

export default ViewPage;
