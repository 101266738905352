import React, { useState, useEffect, useRef } from 'react';
import { supabase } from '../SupabaseClient';
import SecureVideoPlayer from './SecureVideoPlayer';
import ReactCountryFlag from "react-country-flag";
import countries from '../data/countries.json';
import { BiSolidMessageRoundedAdd } from "react-icons/bi";
import axios from 'axios';
import ConfirmationModal from './ConfirmationModal';
import ConfirmationAlert from '../components/ConfirmationAlert';
import RejectionModal from './RejectionModal';
import { IoIosStar } from "react-icons/io";
import RequestFormComponent from '../components/RequestFormComponent';
import { IoMdClose } from 'react-icons/io';

const calculateAge = (dob) => {
    if (!dob) return null;
    const birthDate = new Date(dob);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
};

const getArticle = (word) => {
    if (!word) return 'a';
    const vowels = ['a', 'e', 'i', 'o', 'u'];
    return vowels.includes(word.charAt(0).toLowerCase()) ? 'an' : 'a';
};

const SubmissionConfirmation = ({ onClose }) => {
    return (
        <div className="request-form-overlay">
            <div className="request-form-container">
                <button className="close-button" onClick={onClose}>
                    <IoMdClose />
                </button>

                <div className="confirmation-content">
                    <h2>Question Submitted!</h2>
                    <p>
                        If you don't see a confirmation email in your inbox, please check your spam folder. 
                        If your request is approved, the athlete will respond within 3 days, or you will receive a full refund.
                    </p>
                </div>
            </div>
        </div>
    );
};

function ConversationsTab({ session, onUpdateDashboard, updateEarnings }) {
    const [conversations, setConversations] = useState([]);
    const [selectedConversation, setSelectedConversation] = useState(null);
    const [messages, setMessages] = useState([]);
    const messagesEndRef = useRef(null);
    const [defaultProfilePic, setDefaultProfilePic] = useState(null);
    const [isLoadingMessages, setIsLoadingMessages] = useState(false);
    const [showRequestForm, setShowRequestForm] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);

    // Fetch all unique conversations for the user
    useEffect(() => {
        const fetchConversations = async () => {
            const { data: { user } } = await supabase.auth.getUser();
            const userID = user.id;

            // Get all messages where user is either sender or receiver
            const { data, error } = await supabase
                .from('messages')
                .select('*')
                .or(`sender_id.eq.${userID},receiver_id.eq.${userID}`)
                .in('status', ['approved', 'completed'])
                .order('created_at', { ascending: false });

            if (error) {
                console.error('Error fetching messages:', error);
                return;
            }

            // Get unique conversation partners
            const uniquePartners = new Set();
            const uniqueConversations = [];

            for (const message of data) {
                const partnerId = message.sender_id === userID ? message.receiver_id : message.sender_id;
                
                if (!uniquePartners.has(partnerId)) {
                    uniquePartners.add(partnerId);
                    
                    // Count approved messages for this conversation
                    const approvedCount = data.filter(msg => 
                        msg.sender_id === partnerId && 
                        msg.receiver_id === userID && 
                        msg.status === 'approved' &&
                        msg.q_or_a === 'question'
                    ).length;

                    // Fetch partner's user data
                    const { data: userData, error: userError } = await supabase
                        .from('users')
                        .select('firstName, lastName, username, profile_pic, dob, city, country, role, sport, email, price')
                        .eq('uuid', partnerId)
                        .single();

                    if (!userError && userData) {
                        uniqueConversations.push({
                            partnerId,
                            ...userData,
                            age: calculateAge(userData.dob),
                            approvedCount,
                            lastMessage: message
                        });
                    }
                }
            }
            setConversations(uniqueConversations);
        };

        fetchConversations();
    }, []);

    // Fetch messages for selected conversation
    useEffect(() => {
        const fetchMessages = async () => {
            if (!selectedConversation) return;
            
            setIsLoadingMessages(true);
            
            try {
                const { data: { user } } = await supabase.auth.getUser();
                const userID = user.id;

                const { data, error } = await supabase
                    .from('messages')
                    .select('*')
                    .or(`and(sender_id.eq.${userID},receiver_id.eq.${selectedConversation.partnerId}),and(sender_id.eq.${selectedConversation.partnerId},receiver_id.eq.${userID})`)
                    .in('status', ['approved', 'completed', 'answer'])
                    .order('created_at', { ascending: true });

                if (error) {
                    console.error('Error fetching messages:', error);
                    return;
                }
                setMessages(data);
            } catch (error) {
                console.error('Error:', error);
            } finally {
                setIsLoadingMessages(false);
            }
        };

        fetchMessages();
    }, [selectedConversation]);

    // Auto scroll to bottom of messages
    useEffect(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }, [messages]);

    const getDefaultProfilePic = async () => {
        const { data } = await supabase.storage
            .from('userMedia')
            .getPublicUrl('default/profilePicture/default_profilepic.png');
        return data.publicUrl;
    };

    useEffect(() => {
        const fetchDefaultPic = async () => {
            const defaultPic = await getDefaultProfilePic();
            setDefaultProfilePic(defaultPic);
        };
        fetchDefaultPic();
    }, []);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', {
            month: 'long',
            day: 'numeric',
            year: 'numeric'
        });
    };

    const truncateSubject = (subject) => {
        if (subject.length > 25) {
            return subject.substring(0, 30) + '...';
        }
        return subject;
    };

    const getApprovedCount = async (partnerId) => {
        const { data: { user } } = await supabase.auth.getUser();
        const { data, error } = await supabase
            .from('messages')
            .select('*', { count: 'exact' })
            .eq('receiver_id', user.id)
            .eq('sender_id', partnerId)
            .eq('status', 'approved')
            .eq('q_or_a', 'question');
        
        return error ? 0 : data.length;
    };

    const refreshMessages = async () => {
        // Re-fetch messages for the selected conversation
        if (selectedConversation) {
            const { data: { user } } = await supabase.auth.getUser();
            const userID = user.id;

            const { data, error } = await supabase
                .from('messages')
                .select('*')
                .or(`and(sender_id.eq.${userID},receiver_id.eq.${selectedConversation.partnerId}),and(sender_id.eq.${selectedConversation.partnerId},receiver_id.eq.${userID})`)
                .in('status', ['approved', 'completed', 'answer'])
                .order('created_at', { ascending: true });

            if (!error) {
                setMessages(data);
                // Update dashboard stats
                if (onUpdateDashboard) {
                    await onUpdateDashboard();
                }
            }
        }
    };

    const handleRequestSubmit = () => {
        setShowRequestForm(false);
        setShowConfirmation(true);
    };

    const handleNewRequest = () => {
        setShowRequestForm(true);
    };

    return (
        <div className="conversations-container">
            {conversations.length === 0 ? (
                <div className="conversations-empty">No conversations yet! Respond to a request to start a conversation.</div>
            ) : (
                <>
                    <div className={`conversations-list ${selectedConversation ? 'mobile-hidden' : ''}`}>
                        {conversations.map((conv) => (
                            <div
                                key={conv.partnerId}
                                className={`conversation-entry ${selectedConversation?.partnerId === conv.partnerId ? 'selected' : ''}`}
                                onClick={() => setSelectedConversation(conv)}
                            >
                                <img
                                    src={conv.profile_pic || defaultProfilePic}
                                    alt="Profile"
                                    className="conversation-profile-pic"
                                    onError={(e) => {
                                        if (defaultProfilePic) {
                                            e.target.src = defaultProfilePic;
                                        }
                                    }}
                                />
                                <div className="conversation-user-info">
                                    <div className="conversation-name-container">
                                        <span className="conversation-name">{conv.firstName} {conv.lastName}</span>
                                        {conv.username && <span className="conversation-username">@{conv.username}</span>}
                                    </div>
                                </div>
                                {conv.approvedCount > 0 && (
                                    <div className="conversation-badge">{conv.approvedCount}</div>
                                )}
                            </div>
                        ))}
                    </div>
                    <div className={`messages-container ${!selectedConversation ? 'mobile-hidden' : ''}`}>
                        {selectedConversation && (
                            <>
                                <div className="messages-header mobile-only">
                                    <span onClick={() => setSelectedConversation(null)}><b>Back</b></span>
                                </div>
                                <div className="user-info-banner">
                                    {selectedConversation.role === 'creator' ? (
                                        // Banner for creators
                                        `${selectedConversation.firstName} is ${getArticle(selectedConversation.sport)} ${selectedConversation.sport || '–'} athlete from ${selectedConversation.city || '–'}, ${selectedConversation.country || '–'} `
                                    ) : (
                                        // Banner for regular users
                                        `${selectedConversation.firstName} is ${selectedConversation.age || '–'} years old and from ${selectedConversation.city || '–'}, ${selectedConversation.country || '–'} `
                                    )}
                                    {selectedConversation.country && <ReactCountryFlag countryCode={getCountryCode(selectedConversation.country)} svg />}
                                </div>
                                <div className="messages-list">
                                    {isLoadingMessages ? (
                                        <div className="messages-loading"><div className="loader-ring"><div></div><div></div><div></div><div></div></div></div>
                                        // <div class="loader">nfield</div>
                                    ) : (
                                        <>
                                            {messages.map((message, index) => (
                                                <MessageBubble
                                                    key={message.id}
                                                    message={message}
                                                    conversation={selectedConversation}
                                                    showDate={index === 0 || formatDate(messages[index - 1].created_at) !== formatDate(message.created_at)}
                                                    formatDate={formatDate}
                                                    truncateSubject={truncateSubject}
                                                    onMessageSubmitted={refreshMessages}
                                                    updateEarnings={updateEarnings}
                                                />
                                            ))}
                                            <div ref={messagesEndRef} />
                                            {selectedConversation.role === 'test' && (
                                                <button 
                                                    className="new-request-button"
                                                    onClick={handleNewRequest}
                                                >
                                                    <BiSolidMessageRoundedAdd style={{ marginRight: '8px' }} />
                                                    Make a new request
                                                </button>
                                            )}
                                        </>
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                </>
            )}
            {showRequestForm && (
                <RequestFormComponent 
                    athlete={selectedConversation} 
                    onClose={() => setShowRequestForm(false)}
                    onSubmitSuccess={handleRequestSubmit}
                />
            )}
            {showConfirmation && (
                <SubmissionConfirmation 
                    onClose={() => setShowConfirmation(false)}
                />
            )}
        </div>
    );
}

function MessageBubble({ message, conversation, showDate, formatDate, truncateSubject, onMessageSubmitted, updateEarnings }) {
    const [showContent, setShowContent] = useState(true);
    const [isOwnMessage, setIsOwnMessage] = useState(null);
    const [videoFile, setVideoFile] = useState(null);
    const [videoThumbnail, setVideoThumbnail] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const fileInputRef = useRef(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalConfig, setModalConfig] = useState({
        title: '',
        message: '',
        confirmText: '',
        cancelText: '',
        onConfirm: null,
        showTextarea: false,
    });
    const [rejectionNote, setRejectionNote] = useState("");
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [isRejectionModalOpen, setIsRejectionModalOpen] = useState(false);

    useEffect(() => {
        const checkMessageOwner = async () => {
            const { data: { user } } = await supabase.auth.getUser();
            setIsOwnMessage(message.sender_id === user.id);
        };
        checkMessageOwner();
    }, [message.sender_id]);
    
    const getVideoUrl = (path) => {
        if (!path) return null;
        const { data: { publicUrl } } = supabase.storage
          .from('conversations')
          .getPublicUrl(path);
        return publicUrl;
      };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setVideoFile(file);
        if (file) {
            const videoUrl = URL.createObjectURL(file);
            const videoElement = document.createElement('video');
            videoElement.src = videoUrl;
            videoElement.onloadedmetadata = () => {
                if (videoElement.duration > 41) {
                    alert("The selected video is too long. Please select a video that is 40 seconds or less.");
                    clearVideo();
                    return;
                } else {
                    setVideoThumbnail(videoUrl);
                }
            };
        }
    };

    const clearVideo = () => {
        setVideoThumbnail(null);
        setVideoFile(null);
        if (fileInputRef.current) {
            fileInputRef.current.value = null;
        }
    };

    const openModal = (config) => {
        setModalConfig(config);
        setIsModalOpen(true);
    };

    const handleRejectionButton = () => {
        setIsRejectionModalOpen(true);
    };

    const handleRejectionConfirm = async (note) => {
        try {
            const { data, error } = await supabase
                .from('messages')
                .update({ 
                    status: "athlete rejected", 
                    rejection_note: note 
                })
                .eq('message_id', message.message_id);

            if (error) {
                console.error('Error updating request status:', error);
                setAlertMessage('Failed to reject request. Please try again.');
            } else {
                if (onMessageSubmitted) {
                    onMessageSubmitted();
                }
                setAlertMessage('The request has been rejected');
            }
            setShowAlert(true);
        } catch (error) {
            console.error('Error rejecting request:', error);
            setAlertMessage('Failed to reject request. Please try again.');
            setShowAlert(true);
        }
        setIsRejectionModalOpen(false);
    };

    const handleRejectionCancel = () => {
        setIsRejectionModalOpen(false);
    };

    const handleSubmitButton = () => {
        openModal({
            title: 'Confirm request response',
            message: `Confirm your response to ${conversation.firstName}\'s request.`,
            confirmText: 'Send response',
            cancelText: 'Cancel',
            placeholderText: '',
            onConfirm: handleSubmit,
            showTextarea: false,
        });
        setRejectionNote(rejectionNote);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsModalOpen(false);
        if (!videoFile) return;
        setIsSubmitting(true);
        let videoPath = null;
        
        try {
            // Get current user
            const { data: { user } } = await supabase.auth.getUser();
            
            // Upload video to Supabase
            const videoName = `${message.message_id}_response`;
            const fileExtension = videoFile.name.split('.').pop();
            videoPath = `${message.conversation_id}/${videoName}.${fileExtension}`;
            

            const { data: uploadData, error: uploadError } = await supabase.storage
                .from('conversations')
                .upload(videoPath, videoFile, {
                    contentType: videoFile.type,
                });

            if (uploadError) {
                console.error('Upload error:', uploadError);
                await supabase.storage.from('conversations').remove([videoPath]);
                throw new Error('Video upload failed');
            }

            // Get athlete name
            const { data: userData, error: userError } = await supabase
                .from('users')
                .select('firstName, lastName')
                .eq('uuid', message.receiver_id)
                .single();

            if (userError) {
                throw new Error('Failed to fetch athlete name');
            }

            const athleteName = `${userData.firstName} ${userData.lastName}`;

            // Call submit-response endpoint
            const formData = {
                selectedSubmission: JSON.stringify({
                    conversation_id: message.conversation_id,
                    sender_id: message.sender_id,
                    receiver_id: message.receiver_id,
                    conversation_id: message.conversation_id,
                    payment_amount: message.payment_amount,
                    payment_intent_id: message.payment_intent_id,
                    subject: message.subject,
                    message: message.message,
                    message_id: message.message_id,
                    email: conversation.email,
                    firstName: conversation.firstName
                }),
                athleteName: athleteName,
                videoPath: videoPath
            };
            const response = await axios.post('https://infield-backend.vercel.app/submit-response', formData, {
            // const response = await axios.post('http://localhost:3001/submit-response', formData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.status === 200) {
                clearVideo();
                if (onMessageSubmitted) {
                    await onMessageSubmitted();
                }
                if (message.payment_amount > 0) {
                    // const { amountAfterFees } = response.data;
                    const amountAfterFees = (Math.floor((message.payment_amount - (message.payment_amount * 0.229 + 0.3)) * 100) / 100).toFixed(2);
                    updateEarnings(amountAfterFees);
                }
                // Refresh NewRequestsTab
                if (window.refreshNewRequests) {
                    await window.refreshNewRequests();
                }
                setAlertMessage('Your response has been sent');
                setShowAlert(true);
            } else {
                throw new Error('Failed to submit response');
            }
        } catch (error) {
            console.error('Error submitting video:', error);
            if (error.response) {
                console.error('Error response data:', error.response.data);
                console.error('Error response status:', error.response.status);
                console.error('Error response headers:', error.response.headers);
            }
            if (videoPath) {
                try {
                    await supabase.storage
                        .from('conversations')
                        .remove([videoPath]);
                } catch (cleanupError) {
                    console.error('Failed to clean up uploaded video:', cleanupError);
                }
            }
            setAlertMessage('Failed to submit response. Please try again.');
            setShowAlert(true);
        } finally {
            setIsSubmitting(false);
        }
    };

    const closeAlert = () => {
        setShowAlert(false);
        setAlertMessage('');
    };

    const handleStarClick = async (index) => {
        try {
            const { error } = await supabase
                .from('messages')
                .update({ rating: index + 1 })
                .eq('message_id', message.message_id);

            if (error) throw error;
            
            // Refresh messages to show updated rating
            if (onMessageSubmitted) {
                onMessageSubmitted();
            }
        } catch (error) {
            console.error('Error updating rating:', error);
        }
    };

    // Don't render until we know if it's an own message
    if (isOwnMessage === null) {
        return null;
    }

    return (
        <div className="message-group">
            {showAlert && <ConfirmationAlert message={alertMessage} onClose={closeAlert} />}
            {showDate && <div className="message-date">{formatDate(message.created_at)}</div>}
            <div className={`message-bubble ${isOwnMessage ? 'own-message' : 
                message.status === 'approved' ? 'approved-message' : 'other-message'}`}>
                <div className="message-subject-container">
                    <div className="message-subject">
                        {message.q_or_a === 'answer' ? `Re: ${truncateSubject(message.subject)}` : message.subject}
                    </div>
                    {!isOwnMessage && message.payment_amount !== null && message.payment_amount > 0 && (
                        <div className={`message-price ${message.status === 'completed' ? 'completed' : ''}`}>
                            ${(Math.floor((message.payment_amount - (message.payment_amount * 0.229 + 0.3)) * 100) / 100).toFixed(2)}
                        </div>
                    )}
                </div>
                <div className="message-toggle-container">
                    <div
                        className="message-toggle"
                        onClick={() => setShowContent(!showContent)}
                    >
                        {showContent ? 
                            `Hide content` : 
                            `View content`
                        }
                    </div>
                    {showContent && message.q_or_a === 'answer' && (
                        <div className="message-rating-container">
                            {(!message.rating || message.rating === 0) && (
                                <div className="message-rating-text">Rate this answer</div>
                            )}
                            <div className="message-rating-stars">
                                {[...Array(5)].map((star, index) => (
                                    <IoIosStar
                                        key={index}
                                        className={`message-rating-star ${index < (message.rating || 0) ? 'selected' : ''}`}
                                        onClick={() => handleStarClick(index)}
                                    />
                                ))}
                            </div>
                        </div>
                    )}
                </div>
                {showContent && (
                    <div className="message-content">
                        {message.text_or_video === 'video' ? (
                            <SecureVideoPlayer src={getVideoUrl(message.video_url)} className='conversation-video' />
                        ) : (
                            <div className="message-text">{message.message}</div>
                        )}
                    </div>
                )}
                {message.status === 'approved' && !isOwnMessage && (
                    <div className="message-response-section">
                        {!videoThumbnail ? (
                            <div className="message-actions">
                                <div className="message-button-wrapper">
                                    <input
                                        type="file"
                                        ref={fileInputRef}
                                        accept="video/*"
                                        className="dashboard-video-button"
                                        id={`file-${message.id}`}
                                        onChange={handleFileChange}
                                    />
                                    <label htmlFor={`file-${message.id}`} className="message-button">
                                        Send a response
                                    </label>
                                </div>
                                <button 
                                    onClick={handleRejectionButton} 
                                    className="message-button message-button-reject"
                                >
                                    Reject
                                </button>
                            </div>
                        ) : (
                            <div className="message-response-preview">
                                <SecureVideoPlayer
                                    src={videoThumbnail}
                                    className="conversation-video"
                                />
                                <div className="message-response-buttons">
                                    <input
                                        type="file"
                                        ref={fileInputRef}
                                        accept="video/*"
                                        className="dashboard-video-button-change"
                                        id={`file-change-${message.id}`}
                                        onChange={handleFileChange}
                                    />
                                    <label htmlFor={`file-change-${message.id}`}>
                                        Change video
                                    </label>
                                    <button 
                                        onClick={handleSubmitButton}
                                        disabled={isSubmitting}
                                        className="message-submit-button"
                                    >
                                        {isSubmitting ? 'Submitting...' : 'Submit'}
                                    </button>
                                </div>
                            </div>
                        )}
                        <RejectionModal
                            isOpen={isRejectionModalOpen}
                            onConfirm={handleRejectionConfirm}
                            onCancel={handleRejectionCancel}
                        />
                        <ConfirmationModal
                            isOpen={isModalOpen}
                            title={modalConfig.title}
                            message={modalConfig.message}
                            confirmText={modalConfig.confirmText}
                            cancelText={modalConfig.cancelText}
                            placeholderText={modalConfig.placeholderText}
                            showTextarea={modalConfig.showTextarea}
                            onConfirm={modalConfig.onConfirm}
                            onCancel={() => setIsModalOpen(false)}
                            onTextareaChange={(value) => setRejectionNote(value)}
                        />
                    </div>
                )}
            </div>
        </div>
    );
}

const getCountryCode = (countryName) => {
    // Handle edge case for USA
    if (countryName === "USA" || countryName === "United States") {
        countryName = "United States of America";
    }
    return countries[countryName] || '';
};

export default ConversationsTab; 