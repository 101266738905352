import React, { useState, useRef, useEffect } from 'react';

import { IoCloseOutline } from "react-icons/io5";
import { FaArrowLeft, FaArrowRight, FaDumbbell, FaUserGraduate } from "react-icons/fa";
import { BsCameraReelsFill } from "react-icons/bs";
import { RiMentalHealthFill } from "react-icons/ri";
import '../styles/requestform.css';
import countryCodes from '../data/countries.json';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import { supabase } from '../SupabaseClient';
import { Elements, PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';

// Initialize Stripe
const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`);

// Step 1: Request Details
const PLACEHOLDER_EXAMPLES = [
    '"Give feedback on my serve"',
    '"Exercises to strengthen my ankle stability?"',
    '"Protein and creatine intake for getting faster"',
    '"Blocking tips for shorter players?"',
    '"Milage reccomendations to train for triathlon"',
    '"How to get noticed by college scouts?"',
    '"Recovering after a half-marathon?"',
    '"Strength training for endurance athletes?"',
    '"Preventing shin splints during training?"',
    '"Diet plan for peak performance?"',
    '"How to increase my vertical jump?"',
];

const MAX_FILE_SIZE = 100 * 1024 * 1024; // 100MB in bytes
const MAX_VIDEO_DURATION = 300; // 5 minutes in seconds
const ALLOWED_VIDEO_TYPES = ['video/mp4', 'video/webm', 'video/quicktime'];

const calculateAge = (birthDate) => {
    if (!birthDate) return 0;
    const today = new Date();
    const birth = new Date(birthDate);
    let age = today.getFullYear() - birth.getFullYear();
    const monthDiff = today.getMonth() - birth.getMonth();
    
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birth.getDate())) {
        age--;
    }
    return age;
};

const TOPIC_BUBBLES = [
    { text: "Review my film", icon: <BsCameraReelsFill />    },
    { text: "Injury/rehab advice", icon: <RiMentalHealthFill /> },
    { text: "Exercise tips", icon: <FaDumbbell /> },
    { text: "College recruiting", icon: <FaUserGraduate /> }
];

const RequestStep1 = ({ formData, setFormData }) => {
    const [currentPlaceholder, setCurrentPlaceholder] = useState(0);
    const [isTransitioning, setIsTransitioning] = useState(false);
    const fileInputRef = useRef(null);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setIsTransitioning(true);
            
            // Wait for fade out, then change placeholder
            setTimeout(() => {
                setCurrentPlaceholder(prev => (prev + 1) % PLACEHOLDER_EXAMPLES.length);
                setIsTransitioning(false);
            }, 500); // Half of our transition time
        }, 3000);

        return () => {
            clearInterval(intervalId);
        };
    }, []);

    const handleVideoUpload = async (e) => {
        try {
            const file = e.target.files[0];
            if (!file) return;

            // Validate file type
            if (!ALLOWED_VIDEO_TYPES.includes(file.type)) {
                alert("Please upload a valid video file (MP4, WebM, or MOV)");
                clearVideo();
                return;
            }

            // Validate file size
            if (file.size > MAX_FILE_SIZE) {
                alert("File size must be less than 100MB");
                clearVideo();
                return;
            }

            // Create video element for duration check
            const videoUrl = URL.createObjectURL(file);
            const videoElement = document.createElement('video');
            
            // Use promise to handle video metadata loading
            await new Promise((resolve, reject) => {
                videoElement.onloadedmetadata = resolve;
                videoElement.onerror = reject;
                videoElement.src = videoUrl;
            });

            if (videoElement.duration > MAX_VIDEO_DURATION) {
                alert(`Video must be ${MAX_VIDEO_DURATION} seconds or less`);
                clearVideo();
                URL.revokeObjectURL(videoUrl);
                return;
            }

            setFormData(prev => ({
                ...prev,
                video: file,
                videoUrl: videoUrl,
                message: '',
                messageType: 'video'
            }));
        } catch (error) {
            console.error('Error processing video:', error);
            alert('Error processing video. Please try again.');
            clearVideo();
        }
    };

    const clearVideo = () => {
        if (formData.videoUrl) {
            URL.revokeObjectURL(formData.videoUrl);
        }
        if (fileInputRef.current) {
            fileInputRef.current.value = null;
        }
        setFormData(prev => ({ 
            ...prev, 
            video: null,
            videoUrl: null,
            messageType: ''
        }));
    };

    const handleMessageChange = (e) => {
        if (formData.videoUrl) {
            URL.revokeObjectURL(formData.videoUrl);
        }
        const newValue = e.target.value;
        setFormData(prev => ({
            ...prev,
            message: newValue,
            video: null,
            videoUrl: null,
            messageType: newValue ? 'text' : ''
        }));
    };

    useEffect(() => {
        return () => {
            if (formData.videoUrl) {
                URL.revokeObjectURL(formData.videoUrl);
            }
        };
    }, []);

    return (
        <div className="form-step step-1">
            <h2 className="form-title">Your Question</h2>
            
            <div className="form-group">
                <label className="form-label">Pick a topic, or write your own</label>
                <input
                    type="text"
                    className={`form-input placeholder-fade ${isTransitioning ? 'transitioning' : ''}`}
                    value={formData.subject}
                    onChange={(e) => setFormData(prev => ({ ...prev, subject: e.target.value }))}
                    placeholder={PLACEHOLDER_EXAMPLES[currentPlaceholder]}
                />
                <div className="topic-bubbles">
                    {TOPIC_BUBBLES.map((topic, index) => (
                        <button
                            key={index}
                            className="topic-bubble"
                            onClick={() => setFormData(prev => ({ ...prev, subject: topic.text }))}
                        >
                            <span className="topic-bubble-icon">{topic.icon}</span>
                            {topic.text}
                        </button>
                    ))}
                </div>
            </div>

            <div className="form-group question-choice">
                <label className="form-label">Ask Away!</label>
                <p className="question-subtitle">
                    Your question can either be an uploaded video or a short written message.
                </p>

                <div className="question-options">
                    <div className={`video-option ${formData.messageType === 'video' ? 'active' : ''}`}>
                        <div 
                            className={`file-upload-area ${formData.messageType === 'video' ? 'active' : ''}`}
                            onClick={() => fileInputRef.current?.click()}
                        >
                            <input
                                ref={fileInputRef}
                                type="file"
                                accept="video/*"
                                onChange={handleVideoUpload}
                                disabled={formData.messageType === 'text'}
                                style={{ display: 'none' }}
                            />
                            {formData.videoUrl ? (
                                <div className="video-preview">
                                    <video
                                        width="200"
                                        controls
                                        className="video-thumbnail"
                                    >
                                        <source src={formData.videoUrl} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                    <button 
                                        className="change-video"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            clearVideo();
                                        }}
                                    >
                                        Change video
                                    </button>
                                </div>
                            ) : (
                                <div className="upload-prompt">
                                    <span>Record or upload a video</span>
                                    <span className="upload-subtitle">Maximum 20 seconds</span>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="option-divider">or</div>

                    <div className={`written-option ${formData.messageType === 'text' ? 'active' : ''}`}>
                        <textarea
                            className={`form-input ${formData.messageType === 'text' ? 'active' : ''}`}
                            value={formData.message}
                            onChange={handleMessageChange}
                            placeholder="Write your question (max 500 characters)"
                            maxLength={500}
                            disabled={formData.messageType === 'video'}
                        />
                        {formData.message && (
                            <div className="character-count">
                                {formData.message.length}/500 characters
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

// Step 2: User Profile
const RequestStep2 = ({ formData, setFormData, athlete }) => {
    // Convert countries object to array for dropdown
    const countryOptions = Object.entries(countryCodes).map(([name, code]) => ({
        label: name,
        value: code
    })).sort((a, b) => a.label.localeCompare(b.label));

    return (
        <div className="form-step">
            <h2 className="form-title">Your Information</h2>
            
            <div className="form-row">
                <div className="form-group">
                    <label className="form-label">First Name *</label>
                    <input
                        type="text"
                        className="form-input"
                        value={formData.firstName}
                        onChange={(e) => setFormData(prev => ({ ...prev, firstName: e.target.value }))}
                    />
                </div>

                <div className="form-group">
                    <label className="form-label">Last Name *</label>
                    <input
                        type="text"
                        className="form-input"
                        value={formData.lastName}
                        onChange={(e) => setFormData(prev => ({ ...prev, lastName: e.target.value }))}
                    />
                </div>
            </div>

            <div className="form-group">
                <label className="form-label">Date of Birth</label>
                <input
                    type="date"
                    className="form-input"
                    value={formData.dob}
                    onChange={(e) => setFormData(prev => ({ ...prev, dob: e.target.value }))}
                    max={new Date().toISOString().split('T')[0]}
                />
                <div className="form-subtitle">You must be at least 15 years old to use Infield</div>
            </div>

            <div className="form-row">
                <div className="form-group">
                    <label className="form-label">Country</label>
                    <select
                        className="form-input"
                        value={formData.countryValue}
                        onChange={(e) => {
                            const selectedOption = countryOptions.find(
                                option => option.value === e.target.value
                            );
                            setFormData(prev => ({
                                ...prev,
                                countryValue: e.target.value,
                                countryName: selectedOption.label
                            }));
                        }}
                    >
                        <option value="">Select a country</option>
                        {countryOptions.map(option => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="form-group">
                    <label className="form-label">City</label>
                    <input
                        type="text"
                        className="form-input"
                        value={formData.city}
                        onChange={(e) => setFormData(prev => ({ ...prev, city: e.target.value }))}
                    />
                </div>
            </div>

            <div className="form-group">
                <label className="form-label">Email</label>
                <input
                    type="email"
                    className="form-input"
                    value={formData.email}
                    onChange={(e) => setFormData(prev => ({ ...prev, email: e.target.value }))}
                />
                <div className="form-subtitle">This is the email we will send {athlete.firstName}'s response to!</div>
            </div>
        </div>
    );
};

// Step 3: Payment Selection
const RequestStep3 = ({ formData, setFormData, athlete }) => {
    const baseAmount = ((athlete?.price || 0) / 100).toFixed(2);
    const handleAmountSelect = (amount) => {
        setFormData(prev => ({ ...prev, paymentAmount: amount, paymentConfirmed: true }));
    };

    const handleCustomAmountChange = (e) => {
        const value = e.target.value;
        
        if (value === '') {
            handleAmountSelect('');
            return;
        }

        if (/^\d*\.?\d{0,2}$/.test(value)) {
            handleAmountSelect(value);
        }
    };

    return (
        <div className="form-step">
            <h2 className="form-title">Confirm Payment</h2>
            
            {Number(baseAmount) <= 0? (
                <div className="payment-options">
                    <div className="form-label" style={{fontWeight: 'normal'}}><b>{athlete.firstName} is offering free requests right now!</b></div>
                    <button 
                        className={`payment-button ${formData.paymentAmount === 0 ? 'selected' : ''}`}
                        onClick={() => handleAmountSelect(0)}
                    >
                        Ask a free question
                    </button>
                    <div className="form-label" style={{fontWeight: 'normal'}}>But, you can always send them something for their time:</div>
                    <button 
                        className={`payment-button ${formData.paymentAmount === 5 ? 'selected' : ''}`}
                        onClick={() => handleAmountSelect(5)}
                    >
                        $5
                    </button>
                    <button 
                        className={`payment-button ${formData.paymentAmount === 10 ? 'selected' : ''}`}
                        onClick={() => handleAmountSelect(10)}
                    >
                        $10
                    </button>
                    <div className="custom-amount">
                        <label>Custom Amount:</label>
                        <input
                            type="text"
                            value={formData.paymentAmount !== 5 && formData.paymentAmount !== 10 ? formData.paymentAmount : ''}
                            onChange={handleCustomAmountChange}
                            className="custom-amount-input"
                        />
                        <div className="form-subtitle">Minimum of $5</div>
                    </div>
                </div>
            ) : (
                <div className="payment-options">
                    <div className="form-label">{athlete.firstName}'s question price is ${baseAmount}</div>
                    <button 
                        className={`payment-button ${formData.paymentConfirmed ? 'selected' : ''}`}
                        onClick={() => handleAmountSelect(baseAmount)}
                    >
                        Pay {athlete.firstName} ${baseAmount} for your question
                    </button>
                    <div className="form-label">Remember, you won't be charged unless your question is answered!</div>
                </div>
            )}
        </div>
    );
};

// Step 4: Summary and Submission
const RequestStep4 = ({ formData, setFormData, athlete, clientSecret, paymentIntentId, onSubmitSuccess }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);

    const handleSubmission = async () => {
        setIsSubmitting(true);
        try {
            // Step 2: Check/create user and conversation
            const { data: userResponse, error: userError } = await axios.post('https://infield-backend.vercel.app/check-existing-user', {
                responderUuid: athlete.uuid,
                firstName: formData.firstName,
                lastName: formData.lastName,
                dob: formData.dob,
                city: formData.city,
                country: formData.countryName,
                email: formData.email
            });

            if (userError) {
                throw new Error('Failed to process user data');
            }

            // Step 3: Upload video if needed
            let videoPath = null;
            if (formData.messageType === 'video' && formData.video) {
                try {
                    const fileName = `${Date.now()}_${formData.video.name}`;
                    const filePath = `${userResponse.conversationId}/${fileName}`;
                    
                    const { data, error } = await supabase.storage
                        .from('conversations')
                        .upload(filePath, formData.video, {
                            contentType: formData.video.type
                        });

                    if (error) throw error;
                    videoPath = filePath;
                } catch (error) {
                    if (videoPath) {
                        await supabase.storage
                            .from('conversations')
                            .remove([videoPath]);
                    }
                    throw new Error('Video upload failed');
                }
            }

            // Step 4: Send request
            await axios.post('https://infield-backend.vercel.app/send-request', {
                conversationId: userResponse.conversationId,
                senderId: userResponse.requesterUuid,
                receiverId: athlete.uuid,
                subject: formData.subject,
                messageType: formData.messageType,
                videoPath,
                message: formData.message,
                paymentIntentId,
                paymentAmount: formData.paymentAmount,
                emailData: {
                    reply_to: 'info@infieldconnect.co',
                    requester_email: formData.email,
                    requester_name: formData.firstName,
                    athlete_name: `${athlete.firstName} ${athlete.lastName}`,
                }
            });

            setIsSubmitted(true);
            onSubmitSuccess();
        } catch (error) {
            console.error('Submission failed:', error);
            alert(error.message || 'An error occurred during submission. Please try again.');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="form-step">
            <h2 className="form-title">Review Your Request</h2>
            
            <div className="summary">
                <div className="summary-item">
                    <span>To athlete:</span>
                    <span>{athlete.firstName} {athlete.lastName}</span>
                </div>
                <div className="summary-item">
                    <span>Name:</span>
                    <span>{formData.firstName} {formData.lastName}</span>
                </div>
                <div className="summary-item">
                    <span>Email:</span>
                    <span>{formData.email}</span>
                </div>
                <div className="summary-item">
                    <span>Subject:</span>
                    <span>{formData.subject}</span>
                </div>
                <div className="summary-item">
                    <span>Amount:</span>
                    <span>${Number(formData.paymentAmount).toFixed(2)}</span>
                </div>
            </div>

            {clientSecret ? (
                <div className="payment-form-container">
                    <Elements stripe={stripePromise} options={{ clientSecret }}>
                        <PaymentForm 
                            onSubmit={handleSubmission}
                            isSubmitting={isSubmitting}
                        />
                    </Elements>
                </div>
            ) : (
                <>
                    <button 
                        className="submit-button"
                        onClick={handleSubmission}
                        disabled={isSubmitting}
                    >
                        {isSubmitting ? 'Submitting...' : 'Submit Request'}
                    </button>
                </>
            )}

            <p className="legal-agreement">
                By submitting a request, you consent for us to collect and use your personal data soley for facilitating your experience on the platform. You can learn more by viewing our <a target="_blank"  href="https://www.infieldconnect.co/termsofservice">Terms of Service</a> and <a target="_blank" href="https://www.infieldconnect.co/privacypolicy">Privacy Policy</a>.
            </p>
        </div>
    );
};

// Separate the payment form into its own component with submission logic
const PaymentForm = ({ onSubmit, isSubmitting }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [isComplete, setIsComplete] = useState(false);

    useEffect(() => {
        if (!elements) return;

        const element = elements.getElement(PaymentElement);
        if (!element) return;

        element.on('change', (event) => {
            setIsComplete(event.complete);
        });

        return () => {
            element.off('change');
        };
    }, [elements]);

    const handleSubmit = async () => {
        if (!stripe || !elements || !isComplete) return;
        
        try {
            const { error: paymentError } = await stripe.confirmPayment({
                elements,
                redirect: 'if_required'
            });

            if (paymentError) {
                throw new Error('Please check your payment details and try again.');
            }

            await onSubmit();
        } catch (error) {
            throw error;
        }
    };

    return (
        <div className="payment-form">
            <PaymentElement onChange={(event) => setIsComplete(event.complete)} />
            <button 
                className={`submit-button ${(!isComplete || isSubmitting) ? 'disabled' : ''}`}
                onClick={handleSubmit}
                disabled={!isComplete || isSubmitting}
            >
                {isSubmitting ? 'Submitting...' : 'Submit Request'}
            </button>
        </div>
    );
};

const RequestFormComponent = ({ athlete, onClose, onSubmitSuccess }) => {
    const [step, setStep] = useState(1);
    const [clientSecret, setClientSecret] = useState(null);
    const [paymentIntentId, setPaymentIntentId] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        // Athlete info (from props)
        athlete: athlete.firstName + ' ' + athlete.lastName,
        athleteID: athlete.id,
        // User input fields
        firstName: '',
        lastName: '',
        dob: '', // Changed from dateOfBirth to dob to match schema
        email: '',
        city: '',
        countryName: '',
        countryValue: '',
        // Request details
        subject: '',
        messageType: '', // Will be either 'text' or 'video'
        message: '',
        video: null,
        videoUrl: null, // For preview purposes
        // System fields
        status: 'new',
        paid: 0, // Will be updated in payment step
        paymentConfirmed: false,
    });
    const [isValid, setIsValid] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);

    // Validation functions for each step
    const validateStep = (currentStep) => {
        switch(currentStep) {
            case 1:
                return formData.subject && 
                       ((formData.message && formData.messageType === 'text') || 
                        (formData.video && formData.messageType === 'video'));
            case 2:
                return formData.firstName && 
                       formData.lastName && 
                       formData.dob &&
                       calculateAge(formData.dob) >= 15 && 
                       formData.countryValue && 
                       formData.city && 
                       formData.email;
            case 3:
                if (athlete?.price) {
                    return formData.paymentConfirmed;
                }
                return formData.paymentAmount === 0 || formData.paymentAmount >= 5;
            default:
                return false;
        }
    };

    // Handle form submission
    const handleSubmit = () => {
        // Add your submission logic here
        setIsSubmitted(true);
    };

    // Render different steps
    const renderStepContent = () => {
        switch(step) {
            case 1:
                return <RequestStep1 formData={formData} setFormData={setFormData} />;
            case 2:
                return <RequestStep2 formData={formData} setFormData={setFormData} athlete={athlete} />;
            case 3:
                return <RequestStep3 formData={formData} setFormData={setFormData} athlete={athlete} />;
            case 4:
                return <RequestStep4 
                    formData={formData} 
                    setFormData={setFormData} 
                    athlete={athlete}
                    clientSecret={clientSecret}
                    paymentIntentId={paymentIntentId}
                    onSubmitSuccess={onSubmitSuccess}
                />;
            default:
                return null;
        }
    };

    const handleNextStep = async (currentStep) => {
        if (!validateStep(currentStep)) return;

        // If moving to step 4 and payment is required
        if (currentStep === 3 && formData.paymentAmount > 0) {
            setIsLoading(true);
            try {
                const payload = {
                    amount: Math.round(formData.paymentAmount * 100),
                    currency: 'usd',
                    capture_method: 'manual',
                    metadata: {
                        requestType: formData.messageType,
                        athleteId: athlete.uuid,
                        timestamp: Date.now()
                    },
                    firstName: formData.firstName,
                    lastName: formData.lastName,
                    email: formData.email,
                    athlete_id: athlete.uuid,
                    athlete_username: athlete.username,
                    athlete_stripe_id: athlete.stripe_account_id
                };
                
                
                const response = await axios.post('https://infield-backend.vercel.app/create-payment-intent', payload);

                const { clientSecret, paymentIntentId } = response.data;
                setClientSecret(clientSecret);
                setPaymentIntentId(paymentIntentId);
                setStep(currentStep + 1);
            } catch (error) {
                console.error('Error creating payment intent:', error);
                alert('There was an error setting up the payment. Please try again.');
            } finally {
                setIsLoading(false);
            }
        } else {
            setStep(currentStep + 1);
        }
    };

    const handlePreviousStep = async (currentStep) => {
        if (currentStep === 4 && formData.paymentAmount > 0) {
            setIsLoading(true);
            try {
                await axios.post('https://infield-backend.vercel.app/cancel-payment-intent', {
                    paymentIntentId: paymentIntentId
                });
                setClientSecret(null);
                setPaymentIntentId(null);
            } catch (error) {
                console.error('Error canceling payment intent:', error);
                // Continue with navigation even if cancellation fails
            } finally {
                setIsLoading(false);
            }
        }
        setStep(currentStep - 1);
    };



    return (
        <div className="request-form-overlay">
            <div className="request-form-container">
                <button className="close-button" onClick={onClose}>
                    <IoCloseOutline />
                </button>
                
                <div className="progress-indicator">
                    {[1, 2, 3, 4].map(num => (
                        <div 
                            key={num} 
                            className={`progress-dot ${step >= num ? 'active' : ''}`}
                        />
                    ))}
                </div>

                <div className="form-content">
                    {renderStepContent()}
                </div>

                {!isSubmitted && (
                    <div className="navigation-buttons">
                        {step > 1 && (
                            <button 
                                className="nav-button back"
                                onClick={() => handlePreviousStep(step)}
                                disabled={isLoading}
                            >
                                <FaArrowLeft />
                            </button>
                        )}
                        
                        {isLoading && (
                            <div className="loader-ring">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        )}
                        
                        {step < 4 && (
                            <button 
                                className={`nav-button next ${!validateStep(step) || isLoading ? 'disabled' : ''}`}
                                onClick={() => handleNextStep(step)}
                                disabled={!validateStep(step) || isLoading}
                            >
                                <FaArrowRight />
                            </button>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default RequestFormComponent;
